@import "popup.css";

@font-face {
    font-family: 'iranyekan';
    src: url("../fonts/iranyekanwebregularfanum.woff2") format("woff2"),
    url("../fonts/iranyekanwebregularfanum.woff") format("woff"),
    url("../fonts/iranyekanwebregularfanum.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    --primary-color: #363636;
    --primary-color-light: #e0e0e0;
    --primary-color-dark: #1c1c1c;
    --primary-red: hsl(348, 83%, 47%);
    --grey-transparent: hsla(0, 0%, 50%, 0.5);
    --primary-green: #386641;
}

body {
    font-size: 1.4rem;
    font-family: 'iranyekan', 'sans-serif';
    min-height: 100vh;
}

/* disable scroll and prevent Address-Bar hiding in mobile Browsers */
.disable-scroll {
    overflow: hidden;
    touch-action: none;
}

/*********** button ******************/
.btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    color: var(--primary-color-light);
    font-family: inherit;
    font-size: 1.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 1000px;
    padding: 1.1em;
    border: 1px solid var(--primary-color-light);
    background-color: var(--primary-color);
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
}

.btn::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color-dark);
    border-radius: inherit;
    transform: translate3d(0, 0.6rem, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}


.btn:active {
    background-color: var(--primary-color-dark);
    transform: translate(0, 0.6rem);
}

.btn:active::before {
    transform: translate3d(0, 0, -1em);
}


.btn--circle {
    border-radius: 50%;
    padding: 1.3rem 1.5rem;
    font-size: 1.6rem;
}

.btn--success{
    background-color: var(--primary-green);
    font-size: 1.6rem;
}

.btn--danger{
    background-color: var(--primary-red);
    font-size: 1.6rem;
}
/*********** button ******************/


.page {
    width: 100%;
}


table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;
    text-align: center;
}

thead tr {
    background-color: var(--primary-color);
}

th:first-child, th:last-child,td:first-child, td:last-child {
    padding: 1rem 1.5rem;
}

th {
    padding: 1rem 0.5rem;
    font-size: 2rem;
    color: var(--primary-color-light);
}

td{
    padding: 2rem 0.5rem;
}



tbody tr:nth-child(odd) {
    background-color: hsl(0, 0%, 100%);
}

tbody tr:nth-child(even) {
    background-color: var(--primary-color-light);
}

.configs__ISP-head {
    width: 100%;
}

.configs__name{
    font-family: Arial, Helvetica, sans-serif;
}

.configs__ISP-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    flex-wrap: wrap;
    align-items: center;
}


.configs__ISP-item, .configs__ISP-add {
    width: 100%;
}

.configs__ISP-item--animation{
    animation: scale-up-items 0.5s;
}

.loading-table{
    color: var(--primary-color);
    font-size: 5rem;
    text-align: center;
    margin-top: 5rem;
}

.loading-table--hide{
    display: none;
}

.reload-table{
    display: none;
    font-size: 2rem ;
    margin: 5rem auto;
}

.reload-table--show{
    display: flex;
}

@keyframes scale-up-items {
    from{scale:0;}
    to{scale:1;}
}

@media only screen and (min-width: 37.5em ) {
    .configs__ISP-container {
        flex-direction: row;
        gap: 0.8rem;
        flex-wrap: wrap;
    }


    .configs__ISP-item, .configs__ISP-add {
        width: calc(50% - 0.8rem);
    }
}

@media only screen and (min-width: 48em ) {
    .btn{
        font-size: 1.4rem;
    }
    .configs__ISP-container {
        gap: 1rem;
    }
    .configs__ISP-item, .configs__ISP-add {
        width: calc(33.33% - 1rem);
    }

}

@media only screen and (min-width: 62em ) {
      body {
        font-size: 1.6rem;
    }

    .page {
        width: 90%;
        max-width: 140rem;
        margin: 0 auto;
    }


    table {
        margin-top: 5rem;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
    }

    td, th {
        padding: 2rem !important;
    }


    th {
        font-size: 2.7rem;
    }

    .configs__ISP-item, .configs__ISP-add {
        width: calc(25% - 1rem);
    }
}

@media only screen and (min-width: 75em ) {
    .page {
        width: 80%;
        max-width: 140rem;
        margin: 0 auto;
    }
    .configs__ISP-item, .configs__ISP-add {
        width: calc(20% - 1rem);
    }
}

/* TODO add transition for delete and show of items */

