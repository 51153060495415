
.popup {
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 3rem;
    transition: all 0.3s;
}

.popup--show {
    transform: translateY(-100%);
}

.popup-isp__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.popup-isp__item {
    flex-basis: calc(50% - 0.8rem);
}

.popup-isp__check {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    font-size: 1.25rem;
    color: hsl(0, 0%, 100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s;
}

.popup-isp__check--show {
    visibility: visible;
    opacity: 100%;
}

.popup-isp__item:active {
    transform: scale(0.85);
}

.popup__confirm {
    align-self: center;
    width: 50%;
}


.popup-isp-edit{
    padding: 0;
}

.popup-isp-edit__title{
    text-align: center;
    color: var(--primary-color-light);
    background-color: var(--primary-color);
    padding: 2rem;
}

.popup-isp-edit__content{
    padding: 0 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.popup-isp-edit__date-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap:2rem;
    font-size: 2rem;
}

.popup-isp-edit__date-icon{
    font-size: 3.5rem;
}


.popup-isp-edit__actions{
    display: flex;
    gap: 0.5rem;
}

.popup-isp-edit__confirm, .popup-isp-edit__remove{
    flex: 1 0 0;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-transparent);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
}

.overlay--show {
    visibility: visible;
    opacity: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}


.popup-cancel {
    top: 0;
    left: 0;
    transform: translate(-40%, -50%);
    background-color: #ad0426ff;
    width: 3.6rem;
    border-radius: 50%;
}

@media only screen and (min-width: 37.5em ) {
    .popup-isp{
        padding: 4rem 5rem;
    }

    .popup-isp__item {
        flex-basis: calc(33.33% - 0.8rem);
        gap: 2.5rem;
    }

    .popup__confirm {
        width: 33.33%;
    }

    .popup-isp-edit__content{
        padding: 0 7rem 3rem;
        gap: 2.5rem;
    }

    .popup-isp-edit__date-container{
        justify-content: space-between;
        padding: 0 1rem;
    }

    .popup-isp-edit__actions{
        gap: 1.5rem;
    }
}

@media only screen and (min-width: 48em ) {

    .popup-isp-edit__content{
        padding: 0 10rem 3rem;
    }
}

@media only screen and (min-width: 62em ) {
    .popup{
        width: 70%;
        right: 0;
        margin: 0 auto;
        border-radius: 20px 20px 0 0;
    }

    .popup-isp-edit__title{
        padding: 3rem;
        border-radius: 20px 20px 0 0;
    }
}

@media only screen and (min-width: 675em ) {
    .popup {
        width: 60%;
    }
}

